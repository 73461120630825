import { LoadI18nMessages } from '@tactiq/i18n';
import { MessageDescriptor } from 'react-intl';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import fr from 'javascript-time-ago/locale/fr';
import de from 'javascript-time-ago/locale/de';
import es from 'javascript-time-ago/locale/es';
import it from 'javascript-time-ago/locale/it';
import pt from 'javascript-time-ago/locale/pt';
import ja from 'javascript-time-ago/locale/ja';
import memoizeOne from 'memoize-one';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(fr);
TimeAgo.addLocale(de);
TimeAgo.addLocale(es);
TimeAgo.addLocale(it);
TimeAgo.addLocale(pt);
TimeAgo.addLocale(ja);

const loaded: Record<string, Record<string, MessageDescriptor>> = {};

export const loadI18nMessages: LoadI18nMessages = async (locale) => {
  if (!loaded[locale]) {
    loaded[locale] = await (
      await fetch(`/lang/translations/${locale}.json`)
    ).json();
  }

  return loaded[locale];
};

export const createTimeAgoFormatted = memoizeOne((locale: string) => {
  return new TimeAgo(locale);
});

export const formatTimeAgo = (locale: string, date: Date): string => {
  const formatted = createTimeAgoFormatted(locale);

  return formatted.format(date);
};

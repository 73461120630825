import { LabelConfig, LabelConfigStyleLine } from '@tactiq/model';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ToggleButton,
  ToggleButtonGroup,
} from '../../../../../components/ToggleButtonGroup';

const lineButtons = [
  {
    value: LabelConfigStyleLine.solid,
    label: <FormattedMessage defaultMessage="Solid" id="5Ghl98" />,
  },
  {
    value: LabelConfigStyleLine.dashed,
    label: <FormattedMessage defaultMessage="Dashed" id="LBhyck" />,
  },
  {
    value: LabelConfigStyleLine.dotted,
    label: <FormattedMessage defaultMessage="Dotted" id="ZZAAkR" />,
  },
];

export const LinePicker: React.FC<{
  color: LabelConfig['style']['color'];
  line: LabelConfig['style']['line'];
  onChange: (line: LabelConfig['style']['line']) => void;
}> = ({ line, onChange }) => {
  const onChangeLine = useCallback(
    (newLine: LabelConfig['style']['line']) => {
      onChange(newLine);
    },
    [onChange]
  );

  return (
    <div>
      <label id="line-select" className="mb-2 block text-sm font-semibold">
        <FormattedMessage
          defaultMessage="Line"
          description="Label filter. Line radio group label."
          id="yQaV7m"
        />
      </label>

      <ToggleButtonGroup
        value={line}
        aria-labelledby="line-select"
        onChange={onChangeLine}
      >
        {lineButtons.map((button) => (
          <ToggleButton key={button.value} value={button.value}>
            {button.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};

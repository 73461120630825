import { LabelField, LabelFilter } from '@tactiq/model';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFilterMatchFunction } from '../../../../../helpers/labels';
import { BasicMeeting, FullMeeting } from '../../../../../models/meeting';
import { Alert } from '../../../../../components/Alert';

const generateFilterDescription = (
  filters: LabelFilter[],
  meetings: Pick<FullMeeting, 'id' | 'title'>[]
) => {
  if (!filters.length) {
    return 'Add filters to automatically assign labels to meetings';
  }

  return [
    'Select meetings where ',
    filters
      .map((f) => {
        let field: string = f.field;
        let value = `"${f.value}"`;
        let operation: string = f.operation;

        switch (f.field) {
          case LabelField.duration:
            value = `${f.value} minutes`;
            break;
          case LabelField.participants:
            operation = operation.replace(/s$/, '');
            break;
          case LabelField.participantsCount:
            field = 'number of participants';
            value = `${f.value}`;
            break;
          case LabelField.recurringTo:
            field = 'recurring to';
            operation = '';
            value = `"${
              meetings.find((m) => m.id === f.value)?.title ?? f.value
            }"`;
            break;
          default:
            break;
        }

        return `${field} ${operation} ${value}`;
      })
      .join(' and '),
    '.',
  ].join('');
};

export const LabelFilterDescription: React.FC<{
  filters: LabelFilter[];
  meetings: BasicMeeting[];
}> = ({ filters, meetings }) => {
  const matchedMeetings = useMemo(() => {
    const match = createFilterMatchFunction(meetings, filters);

    return meetings.filter(match);
  }, [meetings, filters]);

  return (
    <Alert
      variant="light"
      severity="info"
      description={
        <>
          {generateFilterDescription(filters, meetings)}
          <br />

          {matchedMeetings.length > 0 ? (
            <div>
              <FormattedMessage
                defaultMessage="{matchedCount} meetings matched the filter. These are some of them:"
                id="0ioH28"
                values={{ matchedCount: <b>{matchedMeetings.length}</b> }}
              />

              <ul>
                {matchedMeetings.slice(0, 3).map((m) => (
                  <li key={m.id}>
                    <a href="" target="_blank" rel="noopener noreferrer">
                      {m.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <b>
              <FormattedMessage
                defaultMessage="No meetings matched this filter"
                id="Hr9jMH"
              />
            </b>
          )}
        </>
      }
    />
  );
};

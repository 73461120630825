import { LabelFilter, LabelField } from '@tactiq/model';

export const fieldDefaults: { [key: string]: LabelFilter } = {
  [LabelField.title]: {
    field: LabelField.title,
    operation: 'contains',
    value: '1-on-1',
  },
  [LabelField.participants]: {
    field: LabelField.participants,
    operation: 'contains',
    value: 'Mr. Smith',
  },
  [LabelField.participantsEmails]: {
    field: LabelField.participantsEmails,
    operation: 'contains',
    value: 'email@example.com',
  },
  [LabelField.participantsCount]: {
    field: LabelField.participantsCount,
    operation: '=',
    value: 2,
  },
  [LabelField.duration]: {
    field: LabelField.duration,
    operation: '>',
    value: 30,
  },
  [LabelField.recurring]: {
    field: LabelField.recurring,
    operation: '=',
    value: true,
  },
  [LabelField.recurringTo]: {
    field: LabelField.recurringTo,
    operation: '=',
    value: '',
  },
};

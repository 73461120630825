import React from 'react';
import { cx } from '../../helpers/utils';

type ToggleButtonGroupContextType = {
  value: any;
  onChange: (value: any) => void;
  style: 'connected' | 'disconnected';
};

const ToggleButtonGroupContext =
  React.createContext<ToggleButtonGroupContextType | null>(null);

export const useToggleButtonGroupContext = (): ToggleButtonGroupContextType => {
  const context = React.useContext(ToggleButtonGroupContext);
  if (!context) {
    throw new Error(
      'ToggleButtonGroup compound components cannot be rendered outside the ToggleButtonGroup component'
    );
  }
  return context;
};

type ToggleButtonGroupProps = {
  children: React.ReactNode;
  onChange: (value: any) => void;
  value: any;
  className?: string;
  style?: 'connected' | 'disconnected';
};

export const ToggleButtonGroup: React.FunctionComponent<
  ToggleButtonGroupProps
> = (props) => {
  const { children, className, style = 'connected' } = props;
  return (
    <ToggleButtonGroupContext.Provider
      value={{
        value: props.value,
        onChange: props.onChange,
        style: style ?? 'connected',
      }}
    >
      <span
        className={
          style === 'connected'
            ? cx(
                'inline-flex overflow-hidden rounded-md border border-gray-300 bg-white shadow-sm',
                'divide-x divide-gray-300',
                className
              )
            : cx('inline-flex gap-x-1', className)
        }
      >
        {children}
      </span>
    </ToggleButtonGroupContext.Provider>
  );
};

type ToggleButtonProps = {
  children: React.ReactNode;
  value: any;
  className?: string;
};

export const ToggleButton: React.FC<ToggleButtonProps> = ({
  children,
  value,
  className,
}) => {
  const {
    value: selectedValue,
    onChange,
    style,
  } = useToggleButtonGroupContext();
  return (
    <button
      type="button"
      className={cx(
        'inline-flex items-center text-sm font-semibold',
        selectedValue === value
          ? 'bg-slate-500 text-white'
          : 'bg-slate-200/60 text-slate-600 hover:bg-slate-200/60',
        style === 'connected' ? 'px-3 py-2' : 'rounded-2xl px-5 py-1',
        className
      )}
      onClick={() => onChange(value)}
    >
      {children}
    </button>
  );
};

import { Loader } from 'lucide-react';
import React, { ReactElement } from 'react';
import { cx } from '../../helpers/utils';

/**
 * That spinny thing that shows that something is pending
 */
export function Spinner({
  size = '1rem',
  className,
}: {
  size?: string;
  className?: string;
}): ReactElement {
  return (
    <Loader
      size={size}
      className={cx('text-slate-400 motion-safe:animate-spin', className)}
    />
  );
}

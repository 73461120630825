import React, { PropsWithChildren } from 'react';

export interface TranscriptSectionProps {
  header: React.ReactNode;
  place: string;
  actions?: React.ReactNode;
}

const TranscriptSection: React.FC<
  PropsWithChildren<TranscriptSectionProps>
> = ({ header, place, children, actions }) => {
  return (
    <div className="relative mt-8">
      <div className="mb-4 flex flex-col items-center gap-2 md:flex-row">
        <div className="grow text-xl font-semibold text-slate-900" id={place}>
          {header}
        </div>
        {actions}
      </div>
      {children}
    </div>
  );
};

export default TranscriptSection;

import React, { useMemo } from 'react';
import { FullMeeting } from '../../../../../models/meeting';
import { Combobox } from '../../../../../components/Combobox';
import { FormattedMessage } from 'react-intl';

export const RecurringMeetingSelect: React.FC<{
  meetings: Pick<FullMeeting, 'id' | 'title'>[];
  meetingId: string;
  onChange: (meetingId: string) => void;
}> = ({ meetingId, meetings, onChange }) => {
  const selectedValue = useMemo(() => {
    return meetings.find((meeting) => meeting.id === meetingId);
  }, [meetingId, meetings]);

  return (
    <Combobox<{ id: string; title: string }>
      label={
        <FormattedMessage defaultMessage="Recurring meeting" id="vkL5kA" />
      }
      highlightWhenSelected={false}
      multiple={false}
      options={meetings}
      value={selectedValue}
      id={(x) => x.id}
      name={(x) => x.title}
      onChange={(next) => {
        onChange(next?.id ?? '');
      }}
      onRemove={() => {
        onChange('');
      }}
    />
  );
};

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from '../../../../components/Alert';

export const LabelsInfo: React.FC = () => {
  return (
    <Alert
      severity="info"
      variant="light"
      description={
        <FormattedMessage
          defaultMessage="You can create labels to group meetings together based on a common properties."
          id="KqX2eg"
          description="Labels info"
        />
      }
    />
  );
};

import { MeetingData } from '..';

export enum LabelField {
  title = 'title',
  participants = 'participants',
  participantsCount = 'participantsCount',
  participantsEmails = 'participantsEmails',
  duration = 'duration',
  recurring = 'recurring',
  recurringTo = 'recurringTo',
}

export type LabelOperationFilter<TValue, TMeeting = MeetingData> = (
  meeting: TMeeting,
  value: TValue,
  uid: string
) => boolean;

export type AsyncLabelOperationFilter<TValue, TMeeting = MeetingData> = (
  meeting: TMeeting,
  value: TValue,
  uid: string
) => Promise<boolean>;

export type LabelOperationsMap<TMeeting = MeetingData> = {
  [LabelField.title]: {
    contains: LabelOperationFilter<string, TMeeting>;
    '=': LabelOperationFilter<string, TMeeting>;
  };
  [LabelField.participants]: {
    contains: LabelOperationFilter<string, TMeeting>;
    '=': LabelOperationFilter<string, TMeeting>;
  };
  [LabelField.participantsEmails]: {
    contains: LabelOperationFilter<string, TMeeting>;
    '=': LabelOperationFilter<string, TMeeting>;
  };
  [LabelField.participantsCount]: {
    '=': LabelOperationFilter<number, TMeeting>;
    '>': LabelOperationFilter<number, TMeeting>;
    '<': LabelOperationFilter<number, TMeeting>;
  };
  [LabelField.duration]: {
    '>': LabelOperationFilter<number, TMeeting>;
    '<': LabelOperationFilter<number, TMeeting>;
  };
  [LabelField.recurring]: {
    '=':
      | AsyncLabelOperationFilter<boolean, TMeeting>
      | LabelOperationFilter<boolean, TMeeting>;
  };
  [LabelField.recurringTo]: {
    '=':
      | AsyncLabelOperationFilter<string, TMeeting>
      | LabelOperationFilter<string, TMeeting>;
  };
};

export type TitleLabelFilter = {
  field: typeof LabelField.title;
  operation: keyof LabelOperationsMap[LabelField.title];
  value: string;
};

export type ParticipantsLabelFilter = {
  field: typeof LabelField.participants;
  operation: keyof LabelOperationsMap[LabelField.participants];
  value: string;
};

export type ParticipantsCountLabelFilter = {
  field: typeof LabelField.participantsCount;
  operation: keyof LabelOperationsMap[LabelField.participantsCount];
  value: number;
};

export type ParticipantsEmailsLabelFilter = {
  field: typeof LabelField.participantsEmails;
  operation: keyof LabelOperationsMap[LabelField.participantsEmails];
  value: string;
};

export type DurationLabelFilter = {
  field: typeof LabelField.duration;
  operation: keyof LabelOperationsMap[LabelField.duration];
  value: number;
};

export type RecurringLabelFilter = {
  field: typeof LabelField.recurring;
  operation: keyof LabelOperationsMap[LabelField.recurring];
  value: boolean;
};

export type RecurringToLabelFilter = {
  field: typeof LabelField.recurringTo;
  operation: keyof LabelOperationsMap[LabelField.recurringTo];
  value: string;
};

export type LabelFilter =
  | TitleLabelFilter
  | ParticipantsLabelFilter
  | ParticipantsCountLabelFilter
  | ParticipantsEmailsLabelFilter
  | DurationLabelFilter
  | RecurringLabelFilter
  | RecurringToLabelFilter;

export enum LabelConfigStyleColor {
  blue = 'blue',
  purple = 'purple',
  red = 'red',
  orange = 'orange',
  green = 'green',
}

export enum LabelConfigStyleLine {
  solid = 'solid',
  dashed = 'dashed',
  dotted = 'dotted',
}

export enum LabelConfigStyleVariant {
  filled = 'filled',
  outlined = 'outlined',
}

export interface LabelConfig {
  id: string;
  name: string;
  description: string;
  style: {
    color?: LabelConfigStyleColor;
    line?: LabelConfigStyleLine;
    variant?: LabelConfigStyleVariant;
  };
  filters: LabelFilter[];
}

export interface MeetingLabelConfig extends LabelConfig {
  isAuto: boolean;
}

import { SerializedEditorState } from 'lexical';

export type RemirrorNodeText = {
  type: 'text';
  text: string;
};

export type RemirrorNodeMention = {
  type: 'mentionAtom';
  attrs: {
    type: 'user';
    name: 'at';
    label: string;
    id: string;
  };
};

export type RemirrorNodeParagraph = {
  type: 'paragraph';
  content?: RemirrorNode[];
};

export type RemirrorNode =
  | RemirrorNodeParagraph
  | RemirrorNodeText
  | RemirrorNodeMention;

export type RemirrorDocument = {
  type: 'doc';
  content: RemirrorNode[];
};

export type LexicalNodeText = {
  type: 'text';
  text: string;
};

export type LexicalNodeMention = {
  type: 'mention';
  text: string;
  mention: {
    displayName: string;
    email: string;
    type: 'USER';
    userId: string;
  };
};

export type LexicalNode = LexicalNodeText | LexicalNodeMention;

export interface CommentData {
  id: string;
  uid: string;
  relatedBlockId: string | null;
  relatedCommentId: string | null;
  meetingId: string;
  content: SerializedEditorState | RemirrorDocument;
  createdAt: number;
  isDeleted?: boolean;
}

export enum MentionType {
  USER = 'USER',
  EMAIL = 'EMAIL',
}

export type UserMention = {
  type: MentionType.USER;
  displayName: string;
  userId: string;
};

export type EmailMention = {
  type: MentionType.EMAIL;
  email: string;
};

export type Mention = UserMention | EmailMention;

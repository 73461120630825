import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { MeetingParticipant } from '../../../graphql/operations';
import { trackWebEvent } from '../../../helpers/analytics';
import { hideWord } from '../../../helpers/meetings';
import { BasicMeeting } from '../../../models/meeting';
import { selectIsHideNamesEnabled } from '../../../redux/selectors';
import { Button } from '../../../components/buttons';

const MAX_PARTICIPANTS = 3;

interface Props {
  meeting: BasicMeeting;
  participants: MeetingParticipant[];
}

const MeetingParticipantWrapperNoButtonClasses = `[&:not(:last-of-type)]:after:content-[','] [&:not(:last-of-type)]:mr-1.5`;

const MeetingParticipantItem: React.FC<{
  participant: MeetingParticipant;
}> = ({ participant }) => {
  const hideNames = useSelector(selectIsHideNamesEnabled);

  return (
    <span className={MeetingParticipantWrapperNoButtonClasses}>
      {hideNames ? hideWord(participant.name) : <span>{participant.name}</span>}
    </span>
  );
};

export const CardMeetingParticipants: React.FC<Props> = (props) => {
  const { participants: allParticipants = [] } = props;

  const [showAllParticipants, setShowAllParticipants] = useState(
    allParticipants.length < MAX_PARTICIPANTS
  );

  const participants =
    allParticipants.length > MAX_PARTICIPANTS && !showAllParticipants
      ? allParticipants.slice(0, MAX_PARTICIPANTS)
      : allParticipants;

  const content = (
    <span className="flex flex-row flex-wrap items-center">
      {participants.map((participant, idx) => (
        <MeetingParticipantItem key={idx} participant={participant} />
      ))}

      {allParticipants.length > participants.length ? (
        <div className="ml-1.5">
          <Button
            size="text"
            variant="text"
            onClick={() => {
              trackWebEvent('Expanded meeting participant list');
              setShowAllParticipants(true);
            }}
          >
            <FormattedMessage
              defaultMessage="+{count} more"
              id="3A0/Q2"
              description="Meeting participants. More"
              values={{ count: allParticipants.length - participants.length }}
            />
          </Button>
        </div>
      ) : null}
    </span>
  );

  return <span>{content}</span>;
};

import { LabelFilter, LabelField } from '@tactiq/model';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RecurringMeetingSelect } from './RecurringMeetingSelect';
import { FullMeeting } from '../../../../../models/meeting';
import { fieldDefaults } from './field-defaults';
import { AlertTriangle, ChevronDown, Info, X } from 'lucide-react';
import { Tooltip } from '../../../../../components/Tooltip';
import { Menu } from '../../../../../components/Menu';
import { Button } from '../../../../../components/buttons';
import { TextInput } from '../../../../../components/TextInput';

const useLabelFields = () => {
  const intl = useIntl();
  return [
    {
      id: LabelField.title,
      name: intl.formatMessage({
        defaultMessage: 'Meeting title',
        id: '/RFxb0',
        description: 'Label filter form. Field select option.',
      }),
    },
    {
      id: LabelField.participants,
      name: intl.formatMessage({
        defaultMessage: 'Participants',
        id: 'hYkVfn',
        description: 'Label filter form. Field select option.',
      }),
    },
    {
      id: LabelField.participantsCount,
      name: intl.formatMessage({
        defaultMessage: 'Number of participants',
        id: 'U9Apd1',
        description: 'Label filter form. Field select option.',
      }),
    },
    {
      id: LabelField.participantsEmails,
      name: intl.formatMessage({
        defaultMessage: 'Participants emails',
        id: 'lRjDPk',
        description: 'Label filter form. Field select option.',
      }),
    },
    {
      id: LabelField.duration,
      name: intl.formatMessage({
        defaultMessage: 'Meeting duration',
        id: 'q7xh7Y',
        description: 'Label filter form. Field select option.',
      }),
    },
    {
      id: LabelField.recurring,
      name: intl.formatMessage({
        defaultMessage: 'Is recurring meeting',
        id: 'cHPgL8',
        description: 'Label filter form. Field select option.',
      }),
    },
    {
      id: LabelField.recurringTo,
      name: intl.formatMessage({
        defaultMessage: 'Recurring to',
        id: 'iNG+Yc',
        description: 'Label filter form. Field select option.',
      }),
    },
  ];
};

const operations = {
  [LabelField.title]: [
    { id: 'contains', name: 'contains' },
    { id: '=', name: '=' },
  ],
  [LabelField.participants]: [
    { id: 'contains', name: 'contains' },
    { id: '=', name: '=' },
  ],
  [LabelField.participantsEmails]: [
    { id: 'contains', name: 'contains' },
    { id: '=', name: '=' },
  ],
  [LabelField.participantsCount]: [
    { id: '=', name: '=' },
    { id: '>', name: '>' },
    { id: '<', name: '<' },
  ],
  [LabelField.duration]: [
    { id: '>', name: '>' },
    { id: '<', name: '<' },
  ],
  [LabelField.recurring]: [{ id: '=', name: '=' }],
  [LabelField.recurringTo]: [{ id: '=', name: '=' }],
};

export const LabelFilterForm: React.FC<{
  filter: LabelFilter;
  meetings: Pick<FullMeeting, 'id' | 'title'>[];
  onChange: (filter: LabelFilter) => void;
  onDelete: () => void;
  hasGoogleCalendar: boolean;
}> = ({ filter, meetings, hasGoogleCalendar, onChange, onDelete }) => {
  const onChangeField = useCallback(
    (value: LabelField) => {
      onChange(fieldDefaults[value]);
    },
    [onChange]
  );
  const labelFields = useLabelFields();

  const onChangeOperation = useCallback(
    (value: string) => {
      onChange({ ...filter, operation: value } as LabelFilter);
    },
    [filter, onChange]
  );

  const renderValueField = (field: LabelField) => {
    switch (field) {
      case LabelField.title:
      case LabelField.participants:
      case LabelField.participantsEmails:
        return (
          <TextInput
            value={filter.value as string}
            onChange={(value) => onChange({ ...filter, value } as LabelFilter)}
          />
        );
      case LabelField.duration:
      case LabelField.participantsCount:
        return (
          <TextInput
            type="number"
            value={filter.value as number}
            onChange={(value) => onChange({ ...filter, value } as LabelFilter)}
            helperText={
              field === LabelField.duration ? (
                <span className="flex items-center gap-x-2 pt-1 text-sm">
                  <Info className="size-3 text-blue-800" />
                  <FormattedMessage
                    defaultMessage="Meeting duration in minutes"
                    id="9LM4M5"
                    description="Label filter form. Duration value field helper text."
                  />
                </span>
              ) : null
            }
          />
        );
      case LabelField.recurring:
        return (
          <Menu>
            <Menu.Trigger>
              <Button variant="light">{filter.value ? 'Yes' : 'No'}</Button>
            </Menu.Trigger>
            {['yes', 'no'].map((value) => (
              <Menu.Item
                onClick={() =>
                  onChange({
                    ...filter,
                    value: value === 'yes',
                  } as LabelFilter)
                }
                value={value}
                key={value}
              >
                {value === 'yes' ? 'Yes' : 'No'}
              </Menu.Item>
            ))}
          </Menu>
        );
      case LabelField.recurringTo:
        return (
          <RecurringMeetingSelect
            meetingId={filter.value as string}
            meetings={meetings}
            onChange={(value) => {
              onChange({ ...filter, value } as LabelFilter);
            }}
          />
        );
    }
  };

  return (
    <div className="flex items-start gap-2">
      {filter.field === LabelField.participantsEmails && hasGoogleCalendar && (
        <Tooltip
          title={
            <FormattedMessage
              defaultMessage="This filter requires Google Calendar integeration. Click to install now"
              id="LKG8mA"
              description="Label filter form. Google Calendar integration warning tooltip."
            />
          }
        >
          <Button
            variant="icon"
            color="warning"
            href="https://workspace.google.com/marketplace/app/tactiqio/399035273123"
            target="_blank"
          >
            <AlertTriangle className="size-5" />
          </Button>
        </Tooltip>
      )}

      <div className="flex flex-1 items-start gap-x-2">
        <Menu>
          <Menu.Trigger>
            <Button variant="light" endIcon={<ChevronDown size="1rem" />}>
              {labelFields.find((x) => x.id === filter.field)?.name}
            </Button>
          </Menu.Trigger>
          {labelFields.map((field) => (
            <Menu.Item key={field.id} onClick={() => onChangeField(field.id)}>
              {field.name}
            </Menu.Item>
          ))}
        </Menu>

        <Menu>
          <Menu.Trigger>
            <Button variant="light" endIcon={<ChevronDown size="1rem" />}>
              {
                operations[filter.field].find((x) => x.id === filter.operation)
                  ?.name
              }
            </Button>
          </Menu.Trigger>
          {operations[filter.field].map((operation) => (
            <Menu.Item
              key={operation.id}
              onClick={() => onChangeOperation(operation.id)}
            >
              {operation.name}
            </Menu.Item>
          ))}
        </Menu>

        {renderValueField(filter.field)}
      </div>

      <Button variant="icon" onClick={onDelete}>
        <X className="size-5" />
      </Button>
    </div>
  );
};

export enum Locale {
  en = 'en',
  es = 'es',
  pt = 'pt',
  fr = 'fr',
  de = 'de',
  it = 'it',
  ja = 'ja',
}

export const DEFAULT_LOCALE = Locale.en;

export const getPreferredLocale = (
  selectedLocale?: string,
  supportedLocales: Locale[] = Object.values(Locale)
): Locale => {
  const supportedLocalesSet = new Set(supportedLocales);
  if (
    selectedLocale &&
    selectedLocale in Locale &&
    supportedLocalesSet.has(selectedLocale as Locale)
  ) {
    return selectedLocale as Locale;
  }

  const locales = navigator.languages.map(
    (lang) => lang.split('-')[0] as Locale
  );
  const preferredLocale = locales.find((locale) => {
    return locale in Locale;
  });

  return preferredLocale && supportedLocalesSet.has(preferredLocale)
    ? preferredLocale
    : DEFAULT_LOCALE;
};

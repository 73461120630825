import {
  LabelConfig,
  LabelField,
  LabelFilter,
  LabelOperationsMap,
  MeetingLabelConfig,
} from '@tactiq/model';
import uniqBy from 'lodash/uniqBy';
import { BasicMeeting, FullMeeting } from '../models/meeting';

export const createOperationsMap = (
  meetings: BasicMeeting[]
): LabelOperationsMap<FullMeeting> => {
  return {
    [LabelField.title]: {
      contains: (meeting: FullMeeting, value: string): boolean =>
        meeting.title.toLowerCase().includes(value.toLowerCase()),
      '=': (meeting: FullMeeting, value: string): boolean =>
        meeting.title.toLowerCase() === value.toLowerCase(),
    },
    [LabelField.participants]: {
      contains: (meeting: FullMeeting, value: string): boolean =>
        meeting.participants.some((participant) =>
          participant.name.toLocaleLowerCase().includes(value.toLowerCase())
        ),
      '=': (meeting: FullMeeting, value: string): boolean =>
        meeting.participants.some(
          (participant) =>
            participant.name.toLocaleLowerCase() === value.toLowerCase()
        ),
    },
    [LabelField.participantsEmails]: {
      contains: (meeting: FullMeeting, value: string): boolean => {
        return (
          meeting.calendarData?.participants?.some((p) => {
            return p.email.toLowerCase().includes(value.toLowerCase());
          }) ?? false
        );
      },
      '=': (meeting: FullMeeting, value: string): boolean => {
        return (
          meeting.calendarData?.participants?.some((p) => {
            return p.email.toLowerCase() === value.toLowerCase();
          }) ?? false
        );
      },
    },
    [LabelField.participantsCount]: {
      '=': (meeting: FullMeeting, value: number): boolean =>
        meeting.participants?.length === value,
      '>': (meeting: FullMeeting, value: number): boolean =>
        meeting.participants?.length > value,
      '<': (meeting: FullMeeting, value: number): boolean =>
        meeting.participants?.length < value,
    },
    [LabelField.duration]: {
      '>': (meeting: FullMeeting, value: number): boolean =>
        meeting.duration > value * 60,
      '<': (meeting: FullMeeting, value: number): boolean =>
        meeting.duration < value * 60,
    },
    [LabelField.recurring]: {
      '=': (meeting: FullMeeting, value: boolean): boolean => {
        return meeting.calendarData?.isRecurring === value;
      },
    },
    [LabelField.recurringTo]: {
      '=': (meeting: FullMeeting, meetingId: string): boolean => {
        const relatedMeeting = meetings.find((m) => m.id === meetingId);

        if (relatedMeeting) {
          return relatedMeeting.pathnameHash === meeting.pathnameHash;
        }

        return false;
      },
    },
  };
};

export const createFilterMatchFunction = (
  meetings: BasicMeeting[],
  filters: LabelFilter[]
): ((meeting: BasicMeeting) => boolean) => {
  const operationsMap = createOperationsMap(meetings);

  return (meeting: BasicMeeting): boolean => {
    return (
      filters.length > 0 &&
      filters.every((filter) => {
        // @ts-expect-error typescript has some troubles with type inference
        return operationsMap[filter.field][filter.operation](
          meeting,
          filter.value
        );
      })
    );
  };
};

export const getMeetingLabels = (
  meeting: BasicMeeting | undefined,
  meetings: BasicMeeting[],
  labels: LabelConfig[],
  isOwner: boolean
): MeetingLabelConfig[] => {
  if (!meeting) {
    return [];
  }

  // Only auto label own meetings
  const autoLabels = isOwner
    ? labels.filter((l) => {
        const matchFunction = createFilterMatchFunction(meetings, l.filters);

        return matchFunction(meeting);
      })
    : [];

  return uniqBy(
    [
      ...autoLabels.map((l): MeetingLabelConfig => ({ ...l, isAuto: true })),
      ...meeting.labels,
    ],
    'id'
  );
};

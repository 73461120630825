import { BasicMeeting, FullMeeting } from '../models/meeting';
import {
  GoogleDriveMeetingTranscriptLink,
  MeetingAccess,
  MeetingCustomRecording,
  MeetingGoogleMeetRecording,
} from '../graphql/operations';

export function isMeetingEditable(meeting?: BasicMeeting): boolean {
  return Boolean(
    meeting &&
      [MeetingAccess.WRITE, MeetingAccess.ADMIN].includes(meeting.access) &&
      !meeting.archivedAt &&
      !meeting.isPreview
  );
}

export enum CanUseAIToolsResult {
  Yes = 'Yes',
  No = 'No',
  IsPreview = 'IsPreview',
  IsArchived = 'IsArchived',
  TooShort = 'TooShort',
}

export function canUseAITools(meeting?: FullMeeting): CanUseAIToolsResult {
  if (!meeting) return CanUseAIToolsResult.No;

  if (meeting.isPreview) return CanUseAIToolsResult.IsPreview;
  if (meeting.archivedAt) return CanUseAIToolsResult.IsArchived;
  if (Math.floor((meeting.speechDuration || meeting.duration) / 60) < 2)
    return CanUseAIToolsResult.TooShort;

  return [
    MeetingAccess.READ,
    MeetingAccess.WRITE,
    MeetingAccess.ADMIN,
  ].includes(meeting.access)
    ? CanUseAIToolsResult.Yes
    : CanUseAIToolsResult.No;
}

export function isMeetingAdmin(meeting?: BasicMeeting): boolean {
  return (
    meeting?.access === MeetingAccess.ADMIN &&
    !meeting.archivedAt &&
    !meeting.isPreview
  );
}

export function isMeetingOwner(uid?: string, meeting?: BasicMeeting): boolean {
  return uid ? meeting?.userId === uid : false;
}

export function isMeetingShared(meeting?: BasicMeeting): boolean {
  if (!meeting?.permissions) {
    return false;
  }

  return Boolean(
    meeting.permissions.allow.emails.length ||
      meeting.permissions.allow.teams.length ||
      meeting.permissions.allow.spaces?.length
  );
}

export function isTactiqMeeting(meeting?: BasicMeeting): boolean {
  return !!meeting?.transcripts?.find(
    (link) => link.__typename === 'TactiqMeetingTranscriptLink'
  );
}

export function selectGoogleMeetingTranscript(
  meeting?: BasicMeeting
): GoogleDriveMeetingTranscriptLink | undefined {
  return meeting?.transcripts?.find(
    (link) => link.__typename === 'GoogleDriveMeetingTranscriptLink'
  ) as GoogleDriveMeetingTranscriptLink | undefined;
}

export function selectMeetingRecordings(
  meeting?: FullMeeting
): (MeetingGoogleMeetRecording | MeetingCustomRecording)[] {
  return [
    ...(meeting?.recordings?.GoogleMeet ?? []),
    ...(meeting?.recordings?.Custom ?? []),
  ];
}

export function hideWord(w: string): string {
  const words = w.split(' ');
  return words.map((w) => hideSingleWord(w)).join(' ');
}

export function hideSingleWord(w: string): string {
  if (w.length < 3) return w;
  return w.substring(0, 2) + '*'.repeat(w.length - 2);
}

declare global {
  interface Window {
    rewardful: any;
    Rewardful: any;
  }
}

const timeout = (ms: number): Promise<undefined> =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const getRewardfulReferral = async (): Promise<string | undefined> => {
  if (!window.rewardful) {
    return undefined;
  }

  const getReferral = (): Promise<string | undefined> => {
    // eslint-disable-next-line promise/param-names
    return new Promise((res) => {
      window.rewardful('ready', () => {
        if (window.Rewardful.referral) {
          res(window.Rewardful.referral);
        } else {
          res(undefined);
        }
      });
    });
  };

  return Promise.race([getReferral(), timeout(1000)]);
};

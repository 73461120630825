import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../components/buttons';
import { ModalDialog } from '../../../components/modals';

export interface DeleteDialogProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  isArchived: boolean;
  loading: boolean;
}

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
  open,
  onClose,
  onDelete,
  isArchived,
  loading,
}) => {
  if (!open) return null;

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={
        isArchived ? (
          <FormattedMessage
            defaultMessage="Delete Meeting?"
            id="5TDm9W"
            description="Delete meeting dialog title."
          />
        ) : (
          <FormattedMessage
            defaultMessage="Archive Meeting?"
            id="2LM8sQ"
            description="Archive meeting dialog title."
          />
        )
      }
      text={
        isArchived ? (
          <FormattedMessage
            defaultMessage="Are you sure you want to delete this meeting?"
            id="ae1IG1"
            description="Delete meeting dialog description"
          />
        ) : (
          <>
            <FormattedMessage
              defaultMessage="Are you sure you want to archive this meeting?"
              id="pnYbBr"
              description="Archive meeting dialog description"
            />
            <br />
            <FormattedMessage
              defaultMessage="Archived meetings are automatically removed after 30 days."
              id="WJeKEb"
              description="Archive meeting dialog description"
            />
          </>
        )
      }
      actions={
        <div className="flex flex-row gap-2">
          <Button onClick={onClose} autoFocus>
            <FormattedMessage
              defaultMessage="Cancel"
              id="PyxZY2"
              description="Button label"
            />
          </Button>
          <Button color="error" loading={loading} onClick={onDelete}>
            {isArchived ? (
              <FormattedMessage
                defaultMessage="Delete"
                id="Bhu3B2"
                description="Button label"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Archive"
                id="tpiEGI"
                description="Button label"
              />
            )}
          </Button>
        </div>
      }
    />
  );
};

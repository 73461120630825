import { logger } from '@tactiq/model';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import * as routes from '../../helpers/routes';
import { createPayPalCustomer } from '../../helpers/api/billing';
import { isProduction } from '../../helpers/firebase/config';
import { setUserLoaded } from '../../redux/modules/global';
import { selectTeam, selectUserPricing } from '../../redux/selectors';
import payPal from '../../img/PayPal.svg';
import { Button } from '../../components/buttons';
import {
  TierPricingDialogSource,
  trackPricingWindowUpgrade,
  trackWebEvent,
} from '../../helpers/analytics';
import { RootState } from '../../redux/store';
import { getCountryCode } from '../../helpers/locale';
import { TeamTier, UserTier } from '../../graphql/operations';

const PAYPAL_KEY = isProduction()
  ? 'Aavu5bhAOYoFz0BkzFvN0M6LiI9YMTO0EL_zrU6IPTokTdwK3UP_ywhuFfxDDJNd2RzSjt2y965NeKkj'
  : 'AdNsDW4Ct9jsHDZPREE4GV9CrhDQHTiOrlvV5GyKgOVwtSTAZb8-SqgNYOik073plwX4TjAUpB5-CUnV';

const processPayPal = async (data: any, details: any) => {
  const subscriptionPrice =
    (data.billing_info.last_payment?.amount?.value || 0) * 100;
  const nextBillingTime = data.billing_info.next_billing_time;
  return createPayPalCustomer(
    data.subscriptionID,
    details.status,
    subscriptionPrice,
    nextBillingTime
  );
};
/**
 * Warning!
 * PayPalButtons and PayPalScriptProvider components accept only initial properties.
 * So be carefull when passing calculated props.
 * @param {unknown} root0 params
 * @param {boolean} root0.isAnnual Is Annual
 * @param {TierPricingDialogSource} root0.source Source of the dialog
 * @param {UserTier} root0.userTier User Tier
 * @param {TeamTier} root0.teamTier Team Tier
 * @see isAnnualRef hack
 * @returns {React.ReactNode} PayPal button
 */
export const PayPal: React.FC<{
  isAnnual: boolean;
  source: TierPricingDialogSource;
  userTier: UserTier;
  teamTier: TeamTier | undefined;
}> = ({ isAnnual, source, userTier, teamTier }) => {
  const userId = useSelector((state: RootState) => state.user.id);
  const team = useSelector(selectTeam);
  const userPricing = useSelector(selectUserPricing);
  const subscription = useRef<Promise<string> | null>(null);
  const isAnnualRef = useRef(isAnnual);
  const dispatch = useDispatch();
  const countryCode = getCountryCode();
  const currency = userPricing.individual.currencies?.find(
    (c) => c.countryCode === countryCode
  );

  useEffect(() => {
    isAnnualRef.current = isAnnual;
  }, [isAnnual]);

  return (
    <div style={{ width: '420px', maxWidth: '100%' }}>
      <PayPalScriptProvider
        options={{
          clientId: PAYPAL_KEY,
          vault: true,
          intent: 'subscription',
          disableFunding: 'card',
          components: 'buttons',
        }}
      >
        <PayPalButtons
          onClick={() => {
            trackPricingWindowUpgrade(
              userId,
              team?.id,
              source,
              isAnnual,
              userTier,
              teamTier,
              currency?.currency,
              'paypal'
            );
            trackWebEvent('Clicked Button - Pay with PayPal');
          }}
          style={{
            layout: 'horizontal',
            shape: 'rect',
            label: 'paypal',
            tagline: false,
            color: 'white',
            disableMaxWidth: true,
          }}
          createSubscription={(data, actions) => {
            if (subscription.current) {
              return subscription.current;
            } else {
              return actions.subscription.create({
                plan_id: isAnnualRef.current
                  ? userPricing.individual.annualIDPayPal
                  : userPricing.individual.monthlyIDPayPal,
                custom_id: userId,
              });
            }
          }}
          onApprove={(data, actions) => {
            dispatch(setUserLoaded(false));
            // Capture the funds from the transaction
            return (
              actions.subscription?.get().then((details) => {
                processPayPal(data, details)
                  .then(() =>
                    window.location.replace(routes.kPaymentSuccessRedirect)
                  )
                  .catch(logger.error);
              }) ?? Promise.resolve()
            );
          }}
        />
      </PayPalScriptProvider>
    </div>
  );
};

// This button is a Paypal button that is not interacting with the PayPal API. We use it to show the local price dialog when needed.
export const PayPal_Alternate: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  return (
    <div>
      <Button
        variant="light"
        fullWidth
        onClick={() => {
          trackWebEvent('Clicked Button - PayPal alternate button');
          onClick();
        }}
      >
        <img src={payPal} className="h-[18px]" alt="PayPal Logo" />
      </Button>
    </div>
  );
};

import { MeetingLinkOrigin, generateMeetingLink, logger } from '@tactiq/model';
import { saveAs } from 'file-saver';
import {
  Archive,
  ArchiveRestore,
  BookText,
  CalendarClock,
  Cloud,
  FileText,
  FileUp,
  Link,
  Mail,
  PencilLine,
  Share2,
  Trash2,
} from 'lucide-react';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { TextInput } from '../../../components/TextInput';
import { Button, TLoading } from '../../../components/buttons';
import { Menu } from '../../../components/Menu';
import {
  archiveMeeting,
  deleteMeeting,
  restoreArchivedMeeting,
  updateMeeting,
} from '../../../graphql/meetings';
import { MeetingReach } from '../../../graphql/operations';
import {
  TierPricingDialogSource,
  trackTranscriptSharingStarted,
  trackWebEvent,
} from '../../../helpers/analytics';
import { fetchApiV2 } from '../../../helpers/api/helpers';
import { baseURL } from '../../../helpers/firebase/config';
import {
  isMeetingAdmin,
  isMeetingEditable,
  isMeetingOwner,
} from '../../../helpers/meetings';
import { kTranscripts } from '../../../helpers/routes';
import { toMarkdown } from '../../../helpers/transcript';
import { AnyMeeting } from '../../../models/meeting';
import {
  deleteMeeting as deleteMeetingAction,
  restoreMeeting,
  archiveMeeting as archiveMeetingAction,
} from '../../../redux/modules/global';
import {
  createSelectMeetingTagsSelector,
  createSelectTransformedTranscriptSelector,
  selectMeetingsMonthAllowance,
  selectTeam,
  selectTimestampOption,
  selectUid,
  selectUserTier,
} from '../../../redux/selectors';
import { RootState } from '../../../redux/store';
import {
  ShareLinkViewSettings,
  fireShareCompletedAnalytics,
} from '../common/meeting-hooks';
import { createStopPropagationHandler } from '../common/stopPropagation';
import { DeleteDialog } from './DeleteDialog';
import { isCurrent } from './helpers';
import { cx } from '../../../helpers/utils';
import { TierPricingDialog } from '../../Credits/TierPricing/TierPricingDialog';
import { AddToSpaceMenu } from '../../Spaces/AddToSpaceMenu';
import { SpaceIcon } from '../../Common/icons';
import { ModalDialog } from '../../../components/modals';
import { Tooltip } from '../../../components/Tooltip';
import { Spinner } from '../../../components/Spinner';

const ActionClasses =
  'hidden md:flex items-center flex-wrap justify-center md:justify-end min-w-[200px]';
interface Props {
  meeting: AnyMeeting;
  fullSize: boolean;
}

const AdaptiveButton = React.forwardRef<
  HTMLButtonElement,
  {
    id?: string;
    text: string | React.ReactElement;
    onClick: (event: React.SyntheticEvent) => void;
    fullSize: boolean;
    icon: React.ReactNode;
    loading?: boolean;
  }
>(function AdaptiveButton({ id, text, onClick, fullSize, icon, loading }, ref) {
  /**
   * if full size
   *  button visible up to sx
   *  icon visible at sx
   * else
   *  button not visible
   *  icon visible
   * end
   */

  if (fullSize) {
    return (
      <Button
        ref={ref}
        textSize="xs"
        size="small"
        variant="naked"
        onClick={onClick}
        startIcon={icon}
        loading={loading}
      >
        {text}
      </Button>
    );
  } else {
    return (
      <Tooltip title={text} placement="top">
        {/* Dropdown and Tooltip will not work together without this "div" */}
        <div>
          <Button id={id} ref={ref} onClick={onClick} variant="icon">
            <span>{loading ? <TLoading size="small" /> : icon}</span>
          </Button>
        </div>
      </Tooltip>
    );
  }
});

/**
 * Meeting Actionc
 * @param {unknown} props props
 * @returns {React.FC} a component
 */
export const MeetingActions: React.FC<Props> = (props) => {
  const { meeting, fullSize = false } = props;

  const navigate = useNavigate();
  const userId = useSelector(selectUid);
  const isPreview = meeting.isPreview ?? false;
  const isArchived = !!meeting.archivedAt;
  const isEditable = isMeetingEditable(meeting);
  const isAdmin = isMeetingAdmin(meeting);
  const isOwner = userId && isMeetingOwner(userId, meeting);
  const isActiveUpload =
    typeof meeting.uploadProgress !== 'undefined' &&
    meeting.uploadProgress > 0 &&
    meeting.uploadProgress <= 100;
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isRenameDialogOpen, setRenameDialogOpen] = useState(false);
  const [showTierPricingDialog, setShowTierPricingDialog] =
    React.useState<boolean>(false);
  const userTier = useSelector(selectUserTier);
  const team = useSelector(selectTeam);
  const [title, setTitle] = useState(meeting.title);
  const [exportInProgress, setExportInProgress] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const [renaming, setRenaming] = useState(false);
  const [restoring, setRestoring] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const monthAllowance = useSelector(selectMeetingsMonthAllowance);
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isRenameDialogOpen) {
      setTitle(meeting.title);
    }
  }, [isRenameDialogOpen, meeting.title]);

  const onArchive = useCallback(async () => {
    trackWebEvent('Clicked Link - Archive Meeting', {
      place: `meeting-actions-${fullSize ? 'text' : 'icons'}`,
    });
    if (isCurrent(meeting)) {
      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage:
            'You can delete this meeting using Tactiq widget during the meeting, or 5 minutes after it ends',
          id: 'uTiTWt',
          description: 'Cannot delete current meeting message.',
        }),
        { variant: 'INFO' }
      );
      trackWebEvent('Could not delete current meeting');
    } else {
      setArchiving(true);
      await archiveMeeting(meeting.id);
      dispatch(archiveMeetingAction(meeting.id));
      setDeleteDialogOpen(false);
      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage: 'Your meeting was archived',
          id: 'M+I4AE',
        }),
        { variant: 'SUCCESS' }
      );

      if (fullSize) {
        navigate(kTranscripts);
      }
    }
    setArchiving(false);
  }, [meeting, intl, fullSize, navigate, dispatch]);

  const onDelete = useCallback(async () => {
    trackWebEvent('Clicked Link - Delete Meeting');
    setDeleting(true);
    try {
      await deleteMeeting(meeting.id);
      dispatch(deleteMeetingAction(meeting.id));
      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage: 'Your meeting was deleted',
          id: 'Dd5k+T',
        }),
        { variant: 'SUCCESS' }
      );
    } catch (error) {
      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage: 'Unable to delete the meeting',
          id: '0ywvqv',
        }),
        { variant: 'ERROR' }
      );
    } finally {
      setDeleteDialogOpen(false);
      setDeleting(false);
    }

    if (fullSize) {
      navigate(kTranscripts);
    }
  }, [meeting, fullSize, navigate]);

  const onRestore = useCallback(async () => {
    trackWebEvent('Clicked Link - Restore Meeting');
    setRestoring(true);
    try {
      await restoreArchivedMeeting(meeting.id);
      dispatch(restoreMeeting(meeting.id));
      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage: 'Your meeting was successfully restored',
          id: 'Dqcdg9',
        }),
        { variant: 'INFO' }
      );
    } catch (error) {
      dispatch(archiveMeetingAction(meeting.id));
      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage: 'Unable to restore the meeting',
          id: 'oEdB2x',
        }),
        { variant: 'ERROR' }
      );
    }
    setRestoring(false);
  }, [meeting, intl, dispatch]);

  const onRename = useCallback(
    async (value: string) => {
      const title = value.trim();
      setRenaming(true);
      await updateMeeting({ id: meeting.id, title });
      setRenaming(false);
      setRenameDialogOpen(false);
      if (meeting.title !== title) {
        trackWebEvent('Transcript Renamed', {
          old_name: meeting.title,
          new_name: title,
          team_id: team?.id,
        });
      }
    },
    [meeting.id, meeting.title, team?.id]
  );

  const onShare = useCallback(() => {
    trackTranscriptSharingStarted(
      fullSize
        ? 'Transcript Navbar - Generic Share'
        : 'Transcripts List - Generic Share',
      team?.id
    );

    navigate(`/transcripts/${meeting.id}/share`);
  }, [meeting.id, fullSize, navigate, team?.id]);

  const onShareByEmail = useCallback(() => {
    trackTranscriptSharingStarted(
      fullSize ? 'Transcript Navbar - Email' : 'Transcripts List - Email',
      team?.id
    );

    navigate(
      `/transcripts/${meeting.id}/share/link?reach=${MeetingReach.RESTRICTED}`
    );
  }, [fullSize, meeting.id, navigate, team?.id]);

  const selectTransformedTranscript = useMemo(
    createSelectTransformedTranscriptSelector,
    []
  );
  const transcript = useSelector((state: RootState) =>
    selectTransformedTranscript(state, meeting.id)
  );

  const selectMeetingTags = useMemo(createSelectMeetingTagsSelector, []);
  const tags = useSelector((state: RootState) =>
    selectMeetingTags(state, meeting.id)
  );

  const timestampOption = useSelector(selectTimestampOption);
  const screenshotUrls = useSelector(
    (state: RootState) => state.global.screenshotUrls
  );

  const onExport = useCallback(() => {
    if (!meeting || !transcript || !('user' in meeting)) {
      return;
    }

    trackWebEvent('Export to TXT button clicked');

    // ensure no empty blocks
    transcript.blocks = transcript.blocks.filter(
      (b) => b.transcript.trim().length > 0
    );

    const link = document.createElement('a');
    const mdcontent = toMarkdown(
      meeting,
      transcript,
      timestampOption,
      tags ?? [],
      screenshotUrls ?? {}
    );
    const file = new Blob([mdcontent], { type: 'text/plain' });
    link.href = URL.createObjectURL(file);
    link.download = `${meeting.title}.txt`;
    link.click();
    URL.revokeObjectURL(link.href);
  }, [meeting, transcript, timestampOption, tags, screenshotUrls]);

  const onExportToTXT = useCallback(() => {
    trackWebEvent('Clicked Link - Export Meeting to TXT', {
      team_id: team?.id,
      place: `meeting-actions-${fullSize ? 'text' : 'icons'}`,
    });

    onExport();
  }, [fullSize, onExport]);

  const buttonClasses = fullSize ? 'h-4 w-4 text-slate-600' : '';

  const exportButton = fullSize ? (
    <Menu>
      <Menu.Trigger>
        <AdaptiveButton
          text={
            <FormattedMessage
              defaultMessage="Export"
              id="rPiBYu"
              description="Button label"
            />
          }
          fullSize
          onClick={() => {
            trackWebEvent('Clicked Link - Export', {
              place: `meeting-actions-${fullSize ? 'text' : 'icons'}`,
            });
          }}
          icon={
            exportInProgress ? (
              <Spinner />
            ) : (
              <FileUp className={buttonClasses} />
            )
          }
        />
      </Menu.Trigger>
      <Menu.Item
        onClick={async () => {
          if (exportInProgress) return;
          setExportInProgress(true);
          trackWebEvent('Clicked Link - Export Meeting to PDF', {
            team_id: team?.id,
            place: `meeting-actions-${fullSize ? 'text' : 'icons'}`,
          });

          const response = await fetchApiV2<Response>(
            `/a/meeting/${meeting.id}/pdf`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/pdf',
              },
              returnRaw: true,
            }
          );
          const blob = await response.blob();
          saveAs(blob, `${meeting.title}.pdf`);

          setExportInProgress(false);
        }}
        icon={<BookText className="h-4 w-4" />}
      >
        <FormattedMessage defaultMessage="Export to PDF" id="nqs1a4" />
      </Menu.Item>
      <Menu.Item
        onClick={onExportToTXT}
        icon={<FileText className="h-4 w-4" />}
      >
        <FormattedMessage defaultMessage="Export to TXT" id="8QFXxx" />
      </Menu.Item>
    </Menu>
  ) : null;

  const deleteButton =
    isOwner || isArchived ? (
      <>
        <AdaptiveButton
          text={
            isArchived ? (
              <FormattedMessage
                defaultMessage="Delete"
                id="Bhu3B2"
                description="Button label"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Archive"
                id="tpiEGI"
                description="Button label"
              />
            )
          }
          fullSize={false}
          onClick={createStopPropagationHandler(() =>
            setDeleteDialogOpen(true)
          )}
          icon={
            isArchived ? (
              <Trash2 className={buttonClasses} />
            ) : (
              <Archive className={buttonClasses} />
            )
          }
        />
        {isDeleteDialogOpen ? (
          <DeleteDialog
            open={isDeleteDialogOpen && !(isArchived && archiving)}
            onClose={() => setDeleteDialogOpen(false)}
            onDelete={() => (isArchived ? onDelete() : onArchive())}
            isArchived={isArchived}
            loading={archiving || deleting}
          />
        ) : null}
      </>
    ) : null;

  let content = null;

  const link = generateMeetingLink(
    baseURL,
    { meetingId: meeting.id },
    MeetingLinkOrigin.SHARING_LINK
  );
  const onCopyLinkClick = useCallback(() => {
    trackTranscriptSharingStarted(
      fullSize
        ? 'Transcript Navbar - Copy Link'
        : 'Transcripts List - Copy Link',
      team?.id
    );

    window.navigator.clipboard.writeText(link).catch(logger.error);

    fireShareCompletedAnalytics({
      team_id: team?.id,
      type: 'Link',
      link_view_settings:
        meeting.permissions?.allow.reach &&
        ({
          [MeetingReach.PUBLIC]: 'Everyone',
          [MeetingReach.RESTRICTED]: 'Restricted',
        }[meeting.permissions?.allow.reach] as ShareLinkViewSettings),
      content: ['Details', 'Highlights', 'Notes', 'Transcript'],
    });

    enqueueSnackbar(
      intl.formatMessage({
        defaultMessage: 'Link copied to clipboard',
        id: '2yCGR2',
      }),
      { variant: 'SUCCESS' }
    );
  }, [fullSize, intl, link, team?.id]);

  if (isArchived) {
    content = (
      <>
        <AdaptiveButton
          text={
            <FormattedMessage
              defaultMessage="Restore"
              id="l2ZaNV"
              description="Button label"
            />
          }
          fullSize={fullSize}
          loading={restoring}
          onClick={createStopPropagationHandler(onRestore)}
          icon={<ArchiveRestore className={buttonClasses} />}
        />
        {deleteButton}
      </>
    );
  } else if (isActiveUpload) {
    content = null;
  } else if (isPreview && isOwner) {
    content = (
      <>
        <Tooltip
          title={
            <FormattedMessage
              defaultMessage="You have hit your free transcript limit for that period. Upgrade to access your full transcripts."
              description="Meeting action buttons. Preview icon tooltip text"
              values={{ monthAllowance }}
              id="0hb1q7"
            />
          }
        >
          <Button
            color="error"
            variant="icon"
            onClick={createStopPropagationHandler(() => {
              setShowTierPricingDialog(true);

              trackWebEvent('Preview meeting card upgrade button clicked');
            })}
          >
            <CalendarClock />
          </Button>
        </Tooltip>
        {showTierPricingDialog && (
          <TierPricingDialog
            userTier={userTier}
            teamTier={team?.tier}
            source={TierPricingDialogSource.MEETING_ACTIONS}
            onClose={() => setShowTierPricingDialog(false)}
          />
        )}
        {deleteButton}
      </>
    );
  } else if (isPreview) {
    content = (
      <>
        <Tooltip
          title={
            <FormattedMessage
              defaultMessage="Owner of this meeting has hit the free transcript limit for that period. They need to upgrade to Pro plan to access the full transcript"
              description="Meeting action buttons. Preview icon tooltip text"
              values={{ monthAllowance }}
              id="FrXih5"
            />
          }
        >
          <Button color="error" variant="icon">
            <CalendarClock />
          </Button>
        </Tooltip>
      </>
    );
  } else if (isEditable) {
    const addToSpace = (
      <AddToSpaceMenu
        meeting={meeting}
        trigger={
          <AdaptiveButton
            text={
              <FormattedMessage defaultMessage="Add to space" id="yDWvLL" />
            }
            fullSize={fullSize}
            onClick={() => {}}
            icon={<SpaceIcon className={buttonClasses} />}
          />
        }
      />
    );

    const emailButton = (
      <AdaptiveButton
        text={
          <FormattedMessage
            defaultMessage="Email"
            id="mdda5R"
            description="Button label"
          />
        }
        fullSize={fullSize}
        onClick={createStopPropagationHandler(onShareByEmail)}
        icon={<Mail className={buttonClasses} />}
      />
    );
    content = (
      <>
        {addToSpace}
        {exportButton}
        {isAdmin && (
          <>
            {emailButton}
            <AdaptiveButton
              text={
                <FormattedMessage
                  defaultMessage="Copy link"
                  id="408xW9"
                  description="Button label"
                />
              }
              fullSize={fullSize}
              onClick={createStopPropagationHandler(onCopyLinkClick)}
              icon={<Link className={buttonClasses} />}
            />

            <AdaptiveButton
              text={
                <FormattedMessage
                  defaultMessage="Share"
                  id="7tw6U2"
                  description="Button label"
                />
              }
              fullSize={fullSize}
              onClick={createStopPropagationHandler(onShare)}
              icon={<Share2 className={buttonClasses} />}
            />
          </>
        )}

        {fullSize && (
          <AdaptiveButton
            text={
              <FormattedMessage
                defaultMessage="Rename"
                id="zJJ244"
                description="Button title"
              />
            }
            loading={renaming}
            fullSize={false}
            onClick={createStopPropagationHandler(() =>
              setRenameDialogOpen(true)
            )}
            icon={<PencilLine className={buttonClasses} />}
          />
        )}
        {isRenameDialogOpen ? (
          <ModalDialog
            open={isRenameDialogOpen}
            onClose={() => setRenameDialogOpen(false)}
            title={
              <FormattedMessage
                defaultMessage="Rename Meeting"
                id="4Tdlx6"
                description="Rename meeting dialog title."
              />
            }
            text={
              <div>
                <FormattedMessage
                  defaultMessage="This will rename the meeting in Tactiq and the transcript file in
            Google Drive."
                  id="3nu9qD"
                  description="Rename meeting dialog description"
                />
                <TextInput
                  autoFocus
                  label={
                    <FormattedMessage
                      defaultMessage="Meeting Title"
                      id="gBAjTP"
                      description="Rename meeting text field label."
                    />
                  }
                  onKeyDown={(event) => {
                    if (event.code === 'Enter') {
                      onRename(title).catch(() => {});
                    }
                  }}
                  value={title}
                  onChange={setTitle}
                />
              </div>
            }
            actions={
              <div className="flex flex-row gap-2">
                <Button onClick={() => setRenameDialogOpen(false)}>
                  <FormattedMessage
                    defaultMessage="Cancel"
                    id="PyxZY2"
                    description="Button label"
                  />
                </Button>
                <Button onClick={() => onRename(title)} loading={renaming}>
                  <FormattedMessage
                    defaultMessage="Rename"
                    id="CxEGkl"
                    description="Button label"
                  />
                </Button>
              </div>
            }
          />
        ) : null}
        {deleteButton}
      </>
    );
  } else {
    content = (
      <>
        {exportButton}
        <Tooltip
          placement="top"
          title={
            <FormattedMessage
              defaultMessage="This meeting is shared with you by another Tactiq user. You don't have permissions to edit it."
              description="Meeting action buttons. Locked icon tooltip text"
              id="Z/UJOy"
            />
          }
        >
          <Button variant="icon">
            <Cloud />
          </Button>
        </Tooltip>
      </>
    );
  }

  return (
    <div className={cx(ActionClasses, fullSize ? 'gap-1' : 'flex-1 gap-2')}>
      {content}
    </div>
  );
};

import { useSelector } from 'react-redux';
import { trackWebEvent } from '../../../helpers/analytics';
import { AnyMeeting, FullMeeting } from '../../../models/meeting';
import { RootState } from '../../../redux/store';
import { useMeetingContext } from '../view/MeetingContext';
import { MeetingBasicFieldsFragment } from '../../../graphql/operations';
import { selectLabels } from '../../../redux/selectors';
import { getMeetingLabels } from '../../../helpers/labels';
import { isMeetingOwner } from '../../../helpers/meetings';

/**
 * useMeeting hook
 * @param {string} overrideMeetingId override meeting id
 * @returns {AnyMeeting | undefined} returns a meeting
 */
export const useMeeting = (
  overrideMeetingId?: string
): AnyMeeting | undefined => {
  const meetingContext = useMeetingContext();
  const meetings = useSelector((state: RootState) => state.global.meetings);
  const userLabels = useSelector(selectLabels);
  const userId = useSelector((state: RootState) => state.user.id);
  let allMeetings: MeetingBasicFieldsFragment[] = [];
  let meeting: AnyMeeting | undefined;

  const meetingId = overrideMeetingId || meetingContext;
  if (!meetingId) return undefined;

  if (meetings.details[meetingId]) {
    meeting = meetings.details[meetingId] as MeetingBasicFieldsFragment;
    allMeetings = Object.values(meetings.details).filter(
      (m) => typeof m !== 'undefined'
    ) as MeetingBasicFieldsFragment[];
    return {
      ...meeting,
      labels: getMeetingLabels(
        meeting as MeetingBasicFieldsFragment,
        allMeetings,
        userLabels,
        isMeetingOwner(userId, meeting)
      ),
    };
  }

  if (meetings.sharedWithMe[meetingId]) {
    meeting = meetings.sharedWithMe[meetingId];
    allMeetings = Object.values(meetings.sharedWithMe).filter(
      (m) => typeof m !== 'undefined'
    ) as MeetingBasicFieldsFragment[];
    return {
      ...meeting,
      labels: getMeetingLabels(
        meeting,
        allMeetings,
        userLabels,
        isMeetingOwner(userId, meeting)
      ),
    };
  }

  if (meetings.archived[meetingId]) {
    meeting = meetings.archived[meetingId];
    allMeetings = Object.values(meetings.archived).filter(
      (m) => typeof m !== 'undefined'
    ) as MeetingBasicFieldsFragment[];
    return {
      ...meeting,
      labels: getMeetingLabels(
        meeting,
        allMeetings,
        userLabels,
        isMeetingOwner(userId, meeting)
      ),
    };
  }

  for (const spaceId in meetings.spaces) {
    const space = meetings.spaces[spaceId];
    if (space && space[meetingId]) {
      meeting = space[meetingId];
      allMeetings = Object.values(space).filter(
        (m) => typeof m !== 'undefined'
      ) as MeetingBasicFieldsFragment[];
      return {
        ...meeting,
        labels: getMeetingLabels(
          meeting,
          allMeetings,
          userLabels,
          isMeetingOwner(userId, meeting)
        ),
      };
    }
  }

  return undefined;
};

/**
 * useFullMeeting hook
 * @param {string} overrideMeetingId override meeting id
 * @returns {FullMeeting | undefined} returns a meeting
 */
export const useFullMeeting = (
  overrideMeetingId?: string
): FullMeeting | undefined => {
  const meeting = useMeeting(overrideMeetingId);
  if (!meeting) return undefined;
  if (!('user' in meeting)) return undefined;
  return meeting;
};

export type ShareType = 'Link' | 'Email' | 'Integration' | 'Team';
export type ShareContent = 'Details' | 'Highlights' | 'Transcript' | 'Notes';
export type ShareLinkViewSettings = 'Public' | 'Restricted' | 'Team';
export type ShareCompletedAnalytics = {
  team_id: string | undefined;
  type?: ShareType;
  integration?: string;
  content?: Array<ShareContent>;
  recipients_email_count?: number;
  recipients_email_total?: number;
  link_view_settings?: ShareLinkViewSettings;
};

export const fireShareCompletedAnalytics = ({
  type,
  integration,
  content,
  recipients_email_count,
  recipients_email_total,
  link_view_settings,
  team_id,
}: ShareCompletedAnalytics): void => {
  trackWebEvent('Transcript Sharing Completed', {
    team_id,
    type,
    integration,
    content,
    recipients_email_count, // current number of recipients,
    recipients_email_total, // Total number of recipients,
    link_view_settings, //  public or restricted
  });
};

import { useQuery } from '@apollo/client';
import {
  MeetingSearchFacetsDocument,
  MeetingSearchFacets,
  SearchMeetingsQueryVariables,
} from '../graphql/operations';
import { useSelector } from 'react-redux';
import { selectUserSpaces } from '../redux/selectors';
import { getUserFriendlyPlatform } from '../helpers/transcript';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

export type SearchFacetType = keyof Omit<MeetingSearchFacets, '__typename'>;
export type SearchFacet = {
  id: string;
  name: string;
  imageUrl?: string;
  icon?: string;
};

/**
 * Fetch facet data from the api and join with the users space data
 */
export function useSearchFacets(): Record<SearchFacetType, Array<SearchFacet>> {
  const intl = useIntl();
  const spaces = useSelector(selectUserSpaces) ?? [];
  const { data } = useQuery(MeetingSearchFacetsDocument, {
    fetchPolicy: 'cache-and-network',
  });

  return {
    labels: data?.meetingSearchFacets?.labels ?? [],
    owners: data?.meetingSearchFacets?.owners ?? [],
    speakers: data?.meetingSearchFacets?.speakers ?? [],
    tags: data?.meetingSearchFacets?.tags ?? [],

    // Convert the language code to a nice name
    languages:
      data?.meetingSearchFacets?.languages
        ?.filter((lang) => lang?.name)
        .map((lang) => ({
          ...lang,
          name:
            new Intl.DisplayNames([], { type: 'language' }).of(lang.name) ??
            lang.name,
        })) ?? [],

    // Convert the platform enum to a nice name
    platforms:
      data?.meetingSearchFacets?.platforms?.map((platform) => ({
        ...platform,
        name: getUserFriendlyPlatform(platform.name, intl),
      })) ?? [],

    // Join with user spaces
    spaces:
      data?.meetingSearchFacets?.spaces.flatMap((facet) => {
        const space = spaces.find((space) => space.id === facet.id);
        return space ? [space] : [];
      }) ?? [],
  };
}

type SearchQueryState = SearchMeetingsQueryVariables['filter'] | undefined;
export function useSearchQueryState(): [
  value: SearchQueryState,
  setValue: (next: Partial<SearchQueryState> | undefined) => void,
] {
  const [searchParams, setSearchParams] = useSearchParams();
  const qValue = searchParams.get('q');

  const setValue = useCallback(
    (next: SearchQueryState) => {
      setSearchParams(next ? { q: JSON.stringify(next) } : {}, {
        replace: true,
      });
    },
    [setSearchParams]
  );

  return useMemo(() => {
    let value: SearchQueryState;
    try {
      if (qValue) value = JSON.parse(qValue);
    } catch {
      // Dont crap out if the parse fails, fall back to the defualt
    }
    return [value, setValue] as const;
  }, [qValue, setValue]);
}

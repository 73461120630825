import React, { PropsWithChildren, useEffect, useState } from 'react';
import { IntlProvider, MessageDescriptor } from 'react-intl';
import { getPreferredLocale, DEFAULT_LOCALE, Locale } from '../helpers/locale';

export type LoadI18nMessages = (
  locale: Locale
) => Promise<Record<string, MessageDescriptor>>;

export const I18nProvider: React.FC<
  PropsWithChildren<{
    selectedLocale?: string;
    supportedLocales?: Locale[];
    loadMessages: LoadI18nMessages;
  }>
> = ({ selectedLocale, supportedLocales, loadMessages, children }) => {
  const preferredLocale = getPreferredLocale(selectedLocale, supportedLocales);
  const [locale, setLocale] = useState(DEFAULT_LOCALE);
  const [messages, setMessages] = useState({});

  useEffect(() => {
    const fetchAndSetMessages = async () => {
      try {
        setMessages(await loadMessages(locale));
        setLocale(preferredLocale);
      } catch (err) {
        console.error(err);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchAndSetMessages();
  }, [locale, preferredLocale, loadMessages]);

  return (
    <IntlProvider
      defaultLocale={DEFAULT_LOCALE}
      locale={locale}
      messages={messages}
      textComponent="span"
    >
      {children}
    </IntlProvider>
  );
};

import React from 'react';

export const SpaceIcon: React.FC<{ className: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 12H22M18 8V16M5.5 8.5L9 12L5.5 15.5L2 12L5.5 8.5ZM12 2L15.5 5.5L12 9L8.5 5.5L12 2ZM12 15L15.5 18.5L12 22L8.5 18.5L12 15Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

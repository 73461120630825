import { isAfter } from 'date-fns';
import { MeetingData } from '../models/meeting';

export enum MeetingLinkOrigin {
  GOOGLE_WORKSPACE_ADDON = 'gwa',
  GOOGLE_CALENDAR = 'gc',
  SHARING_EMAIL = 'se',
  SHARING_LINK = 'sl',
  SHARING_HIGHLIGHT_COPY = 'shc',
  GOOGLE_DRIVE_DOCUMENT = 'gdd',
  NOTION = 'n',
  CONFLUENCE = 'c',
  SALESFORCE = 'sfdc',
  PDF_EXPORT = 'pdf',
  TXT_EXPORT = 'txt',
  COMMENT_EMAIL = 'ce',
  CHANGE_EMAIL = 'che',
  ZAPIER = 'z',
  MEETING_ADDED_TO_SPACE = 'mats',
}

export type ScreenshotAction = string;

export const generateMeetingLink = (
  baseUrl: string,
  meeting: Pick<MeetingData, 'meetingId'>,
  origin: MeetingLinkOrigin,
  params: {
    action?: 'share';
    screenshotMessageId?: string;
    blockId?: string;
  } = {}
): string => {
  const { action, screenshotMessageId, blockId } = params;
  const url = new URL(`/api/2/u/m/r/${meeting.meetingId}`, baseUrl);

  url.searchParams.append('o', origin);

  if (action) {
    url.searchParams.append('a', action);
  }

  if (screenshotMessageId) {
    url.searchParams.append('s', String(screenshotMessageId));
  }

  if (blockId) {
    url.searchParams.append('b', String(blockId));
  }

  return url.toString();
};

export const isMeetingCreatedInTheCurrentCycle = (
  meetingLike: Pick<MeetingData, 'created'>,
  lastRenewDate: number
): boolean => {
  const created = (meetingLike.created.seconds ?? 0) * 1000;
  return isAfter(new Date(created), new Date(lastRenewDate));
};

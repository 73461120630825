import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../components/buttons';
import { TeamPreviewUpgradeInvoice } from '../../graphql/operations';
import { trackWebEvent } from '../../helpers/analytics';
import { useAsyncEffect } from 'use-async-effect';
import { LoadingContent } from '../Landing/Loading/LoadingComponent';
import { Alert } from '../../components/Alert';
import { ModalDialog } from '../../components/modals';

export const PreviewInvoice: React.FC<{
  invoiceProducer: (
    approved: boolean
  ) => Promise<TeamPreviewUpgradeInvoice | undefined | false>;
  open: boolean;
  onClose: () => void;
  tag: string;
  description: React.ReactNode;
}> = ({ open, onClose, invoiceProducer, tag, description }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [invoice, setInvoice] = useState<
    TeamPreviewUpgradeInvoice | undefined
  >();

  const formatter = useMemo(() => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: invoice?.currency ?? 'USD',
    });
  }, [invoice?.currency]);

  useAsyncEffect(async () => {
    if (open && !isLoading && !invoice) {
      setIsLoading(true);

      const invoice = await invoiceProducer(false);
      if (invoice !== false) setInvoice(invoice);

      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, invoiceProducer, invoice]);

  useEffect(() => {
    if (open) {
      trackWebEvent('PreviewInvoice - Opened', { tag });
    }
  }, [open, tag]);

  const close = useCallback(() => {
    setInvoice(undefined);
    onClose();
  }, [onClose]);

  const content =
    isLoading || !invoice ? (
      <LoadingContent />
    ) : (
      <div className="flex flex-col gap-2">
        <Alert severity="info" description={description} />

        {invoice?.lines?.map(({ amount, description }, index) => (
          <div key={index} className="flex justify-between gap-4">
            <span>{description}</span>
            <span>{formatter.format(amount / 100)}</span>
          </div>
        ))}
        <hr />
        {invoice?.discount && (
          <>
            <div className="flex justify-between gap-4">
              <span>{invoice.discount.name}</span>
              <span>
                {invoice.discount.percent_off}% off for{' '}
                {invoice.discount.duration_in_months} months
              </span>
            </div>
            <hr />
          </>
        )}
        <div className="flex justify-between gap-4">
          <FormattedMessage defaultMessage="Total:" id="q4EmsW" />
          <span className="font-bold">
            {formatter.format(invoice.total / 100)}
          </span>
        </div>
      </div>
    );

  if (!open) return null;

  return (
    <ModalDialog
      open={open}
      onClose={close}
      title={
        <p className="pr-[150px]" id="alert-dialog-title">
          <FormattedMessage defaultMessage="Order summary" id="SB//YQ" />
        </p>
      }
      text={content}
      actions={
        <div className="flex flex-row gap-2">
          <Button
            loading={isLoading}
            onClick={async () => {
              trackWebEvent('PreviewInvoice - Confirmed', { tag });
              try {
                setIsLoading(true);
                const invoice = await invoiceProducer(true);
                if (invoice === undefined) {
                  window.location.reload();
                }
              } catch {
                close();
              }
            }}
            autoFocus
          >
            <FormattedMessage defaultMessage="Confirm" id="N2IrpM" />
          </Button>
          <Button
            variant="soft"
            onClick={() => {
              trackWebEvent('PreviewInvoice - Cancelled', { tag });
              close();
            }}
          >
            <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
          </Button>
        </div>
      }
    />
  );
};

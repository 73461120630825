import { LabelConfig } from '@tactiq/model';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from '../../../../../components/buttons';
import { ModalDialog } from '../../../../../components/modals';
import { selectHasGoogleCalendar } from '../../../../../redux/selectors';
import { RootState } from '../../../../../redux/store';
import { LabelsInfo } from '../Info';
import { LabelForm, withNameError } from './Form';

export const LabelEditDialog: React.FC<{
  title: React.ReactNode;
  buttonText: React.ReactNode;
  open: boolean;
  existingLabelNames: string[];
  loading: boolean;
  defaultLabel: LabelConfig;
  showInfo: boolean;
  onSave: (config: LabelConfig) => void;
  onClose: () => void;
}> = ({
  title,
  buttonText,
  open,
  loading,
  defaultLabel,
  existingLabelNames,
  showInfo,
  onSave,
  onClose,
}) => {
  const [config, setConfig] = React.useState<LabelConfig>(defaultLabel);
  const nameError = withNameError(config.name, existingLabelNames);
  const error = useMemo(() => {
    return nameError;
  }, [nameError]);

  useEffect(() => {
    setConfig(defaultLabel);
  }, [defaultLabel]);

  const meetings = Object.values(
    useSelector((state: RootState) => state.global.meetings.my)
  );
  const hasGoogleCalendar = useSelector(selectHasGoogleCalendar);

  const onCreateClick = useCallback(() => {
    if (error) return;
    onSave(config);
  }, [config, error, onSave]);

  const handleClose = useCallback(() => {
    if (!loading) {
      onClose();
    }
  }, [onClose, loading]);

  if (!open) return null;

  return (
    <ModalDialog
      open={open}
      onClose={handleClose}
      title={title}
      actions={
        <>
          <Button onClick={onCreateClick} loading={loading}>
            {buttonText}
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
          </Button>
        </>
      }
    >
      <div className="mx-auto w-full max-w-[900px]">
        {showInfo && (
          <div className="mb-4">
            <LabelsInfo />
          </div>
        )}
        <LabelForm
          config={config}
          meetings={meetings}
          existingLabelNames={existingLabelNames}
          hasGoogleCalendar={hasGoogleCalendar}
          onChange={setConfig}
        />
      </div>
    </ModalDialog>
  );
};

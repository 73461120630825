import { SyntheticEvent } from 'react';

export type Handler = (event: any) => void;

export function stopPropagation(event: SyntheticEvent): void {
  event.stopPropagation();
  event.preventDefault();
}

export function createStopPropagationHandler<TEvent = SyntheticEvent>(
  handler: Handler | (() => void)
): Handler {
  return (event: TEvent): void => {
    stopPropagation(event as unknown as SyntheticEvent);
    handler(event);
  };
}
